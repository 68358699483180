import type Game from "../../game";
import GameModeStandard from "./standard";
import { GameRulesTeamSelection, GameRulesTeamTransfers } from "../../../enum/game";
import MAP_SHAPES from "../../../../components/game/map/shapes";
import { createPlayerListItemIconTeam } from "../../../../components/game/play/playerlist/icons/team";
import { GameRulesTeamDefense } from "../../../enum/game";
import formatList from "../../../util/formatlist";
import getItemById from "../../../util/getItemById";
import defaultColors from "../../../../components/mapedit/countryColors";
import { ApiCountry, ApiSeat } from "../../../types/apigame";

export default class GameModeTeams extends GameModeStandard {
    override getGameModeName(): string {
        return 'Team Domination';
    }

    override getGameModeDescription(): string {
        return 'Work as a team to take over the world!';
    }

    override getGameModeIconName(): string {
        return 'team';
    }

    constructor(gameController: Game) {
        super(gameController);
    }

    public static getTeamNameForTeam(team: number): string {
        return String.fromCharCode(65 + team);
    }

    public override areAllies(seat1: number, seat2: number): boolean {
        const seat1Object = this.gameController.getSeatObjectBySeatNumber(
            seat1,
        );

        const seat2Object = this.gameController.getSeatObjectBySeatNumber(
            seat2,
        );

        if (!seat1Object || !seat2Object) {
            return false;
        }

        return seat1Object.team === seat2Object.team;
    }

    public override canDeploy(
        seatNumber: number,
        countryId: number,
    ): boolean {
        const rules = this.gameController.getRules();
        if (!rules.teamPlacement) {
            return super.canDeploy(seatNumber, countryId);
        }

        const seatObject = this.gameController.getSeatObjectBySeatNumber(seatNumber);

        if (!seatObject) {
            return false;
        }

        const country = this.gameController.getCountryById(countryId);
        if (!country || country.seatNumber < 0) {
            return false;
        }

        if (country.seatNumber === seatNumber) {
            return true;
        }

        return this.areAllies(seatNumber, country.seatNumber);
    }

    public override canFortifyFrom(seatNumber: number, fromCountryId: number): boolean {
        const rules = this.gameController.getRules();
        if (rules.teamTransfers !== GameRulesTeamTransfers.MoveTeammate) {
            return super.canFortifyFrom(seatNumber, fromCountryId);
        }

        const fromCountry = this.gameController.getCountryById(fromCountryId);
        if (!fromCountry) {
            return false;
        }

        if (fromCountry.seatNumber < 0) {
            return false;
        }

        // transfers allowed from any team member to any team member
        return this.areAllies(fromCountry.seatNumber, seatNumber);
    }

    public override canFortify(
        seatNumber: number,
        fromCountryId: number,
        toCountryId: number,
    ): boolean {
        const rules = this.gameController.getRules();
        if (rules.teamTransfers === GameRulesTeamTransfers.None) {
            return super.canFortify(seatNumber, fromCountryId, toCountryId);
        }

        const fromCountry = this.gameController.getCountryById(fromCountryId);
        const toCountry = this.gameController.getCountryById(toCountryId);

        if (!fromCountry || !toCountry) {
            return false;
        }

        if (fromCountry.seatNumber < 0 || toCountry.seatNumber < 0) {
            return false;
        }

        if (!this.gameController.hasBorder(fromCountryId, toCountryId)) {
            return false;
        }

        if (fromCountry.seatNumber === toCountry.seatNumber) {
            return true;
        }

        if (rules.teamTransfers === GameRulesTeamTransfers.ToTeammate) {
            // transfers allowed from player to any team member
            if (fromCountry.seatNumber !== seatNumber) {
                return false;
            }
        }
        if (rules.teamTransfers === GameRulesTeamTransfers.MoveTeammate) {
            // transfers allowed from any team member to any team member
            if (!this.areAllies(fromCountry.seatNumber, seatNumber)) {
                return false;
            }
        }

        return this.areAllies(fromCountry.seatNumber, toCountry.seatNumber);
    }

    public override getFortifiableCountries(seatNumber: number): ApiCountry[] {
        const rules = this.gameController.getRules();
        if (rules.teamTransfers === GameRulesTeamTransfers.None) {
            return super.getFortifiableCountries(seatNumber);
        }

        return this.gameController.game.countries
            .filter(
                (country) => this.areAllies(seatNumber, country.seatNumber),
            );
    }

    protected override getFortifyPathFindTerminalNodes(seatNumber: number): number[] {
        const rules = this.gameController.getRules();
        if (rules.teamTransfers === GameRulesTeamTransfers.None) {
            return super.getFortifyPathFindTerminalNodes(seatNumber);
        }

        if (rules.teamTransfers === GameRulesTeamTransfers.MoveTeammate) {
            // transfers allowed from any team member to any team member
            // so all countries in our team are just fine, nothing to mark terminal
            return [];
        };

        // so this is rules.teamTransfers === GameRulesTeamTransfers.ToTeammate

        let countries = this.getFortifiableCountries(seatNumber);

        // transfers allowed from player to any team member
        // so we need to stop searching when we get to anyone else's country
        countries = countries.filter((country) => country.seatNumber !== seatNumber)

        return countries.map((country) => country.countryId);
    }

    public override getNameForSeat(seatNumber: number): string {
        const seatName = super.getNameForSeat(seatNumber);
        const teamName = this.getTeamNameForSeat(seatNumber);

        return `[${teamName}] ${seatName}`;
    }

    public override getLobbyIcon(seatObject: any): any {
        const rules = this.gameController.getRules();

        if (rules.teamSelection !== GameRulesTeamSelection.Manual) {
            return null;
        }

        if (seatObject.team == null) {
            return null;
        }

        const shapeIndex = super.getShapeIndex(seatObject.team);
        const shape = MAP_SHAPES[shapeIndex];
        const color = getItemById(defaultColors, seatObject.color)

        return createPlayerListItemIconTeam(
            color?.hex ?? '#000000',
            shape,
        );
    }

    public override getPlayerListTagIconForSeat(seatNumber: number): any {
        const shapeIndex = this.getShapeIndex(seatNumber);
        const shape = MAP_SHAPES[shapeIndex];
        const color = this.gameController.getColorForSeat(seatNumber);
        return createPlayerListItemIconTeam(
            color.hex,
            shape,
        );
    }

    public override getShapeIndex(seatNumber: number): number {
        if (seatNumber < 0) {
            return 0;
        }

        const seatObject = this.gameController.getSeatObjectBySeatNumber(seatNumber);
        if (!seatObject) {
            return 0;
        }

        return super.getShapeIndex(seatObject.team ?? seatObject.seatNumber);
    }

    public getTeamNameForSeat(seatNumber: number): string {
        const seatObject = this.gameController.getSeatObjectBySeatNumber(seatNumber);
        return GameModeTeams.getTeamNameForTeam(seatObject.team);
    }

    public override getWinner(): string {
        const turn = this.gameController.game.turn;

        const names = this.getWinnerSeats()
            .map((seatObject) => seatObject.name);

        const namesStr = formatList(names);
        return `Team ${this.getTeamNameForSeat(turn)} (${namesStr})`;
    }

    public override getWinnerSeats(): ApiSeat[] {
        const turn = this.gameController.game.turn;

        return this.gameController.game.seats
            .filter((seatObject) => this.areAllies(seatObject.seatNumber, turn));
    }

    public override isGameOver(countryToExclude?: ApiCountry): boolean {
        const teamsPlaying = {};

        this.gameController.game.countries.forEach(
            (country) => {
                const seatObject = this.gameController.getSeatObjectBySeatNumber(
                    country.seatNumber,
                );

                if (
                    country.seatNumber >= 0
                    && seatObject.accepted
                    && (
                        !countryToExclude
                        || countryToExclude.countryId !== country.countryId
                    )
                ) {
                    teamsPlaying[seatObject.team] = true;
                }
            },
        );

        return Object.keys(teamsPlaying).length <= 1;
    }
}
